import React from "react"

import Layout from "../components/layout"
import Snippet from "../components/snippet"

const LocationPage = () => (
	<Layout
		meta={{
			title: "Locations",
			description: "Online venue fpr Accento's talks.",
			keywords: "location",
			path: "/location",
		}}
		wide
	>
		<Snippet id="locations" />
		{/* <Snippet id="locations-hotels" /> */}
	</Layout>
)

export default LocationPage
